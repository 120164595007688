<template class="m-0">
    <div>
        <div class="positionGeneralDiv">
            <div class="positionDiv">
                <section>
                    <div class="row positionGenralImg">
                        <v-img
                            class="imgSet"
                            lazy-src="https://picsum.photos/id/11/10/6"
                            max-height="175"
                            max-width="100%"
                            src="../../../assets/login-img.jpg"
                        ></v-img>
                    </div>
                    <h1>
                        Seja bem vindo<br />
                        <span>à nossa plataforma!</span>
                    </h1>
                    <div class="favIconDiv"></div>

                    <section class="portals-container">
                        <div class="text-center mt-10 mb-9">
                            <span style="font-size: 15px" class="font-weight-normal textTitle"
                                >Escolha o portal de acesso:</span
                            >
                        </div>

                        <v-row
                            align="center"
                            justify="center"
                            v-if="originatorsRequestStatus === 'START'"
                        >
                            <v-progress-circular
                                :size="70"
                                :width="6"
                                color="secondary"
                                indeterminate
                            ></v-progress-circular>
                        </v-row>

                        <v-row
                            class="mb-2 mt-3 portal-item"
                            align="center"
                            justify="center"
                            v-for="portal in originators"
                            v-bind:key="portal.key"
                        >
                            <v-col cols="6" md="6" class="img-industry">
                                <img :src="getImgUrl(portal.apiPhotoUrl)" />
                            </v-col>

                            <v-col cols="12" sm="6" md="6" align="right" justify="center">
                                <v-menu
                                    content-class="elevation-1"
                                    bottom
                                    origin="center center"
                                    transition="scale-transition"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="buttonColorPortal"
                                            v-bind="attrs"
                                            :title="portal.nome"
                                            v-on="on"
                                            outlined
                                            >Selecione o Produto<span
                                                ><v-icon size="17" class="iconButtonProd"
                                                    >mdi-chevron-down</v-icon
                                                ></span
                                            ></v-btn
                                        >
                                    </template>

                                    <v-list class="mt-9 custom-menu">
                                        <v-list-item
                                            @click="selectProduct(item, portal)"
                                            v-for="(item, i) in portal.products"
                                            :key="i"
                                        >
                                            <v-list-item-title>{{
                                                item.register.name
                                            }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>
                            <hr />
                        </v-row>
                    </section>
                </section>

                <v-layout justify-center class="footer">
                    <span> © 2021 Farm Investimentos. Todos os direitos reservados. </span>
                </v-layout>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getImgUrl } from '@/helpers/images.js';
export default {
    name: 'loginPortal',
    components: {},
    data: () => ({
        produtoEscolhido: true,
        getImgUrl,
    }),
    async mounted() {
        await this.getOriginators();
    },
    computed: {
        ...mapGetters({
            originators: 'base/originators',
            originatorsRequestStatus: 'base/originatorsRequestStatus',
            selectedProduct: 'base/selectedProduct',
        }),
    },
    methods: {
        ...mapActions({
            updateCurrentProduct: 'base/updateCurrentProduct',
            getOriginators: 'base/getOriginators',
        }),
        enviarPortal() {
            this.$router.push({ path: '/admin/dashboard' });
        },
        selectProduct(item, portal) {
            this.updateCurrentProduct({ ...item, parent: portal });
            this.$router.push({ path: '/admin/dashboard' });
        },
    },
};
</script>
<style lang="scss" scoped>
@import './Login.scss';
@import './LoginPortal.scss';
</style>
